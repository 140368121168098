<!-- <template >
  <div class="WatUxQuantiProgressBar">
      <div  @mouseover="showSuccessPercentage = true" @mouseleave="showSuccessPercentage = false" class="WatUxQuantiProgressBar--Success" :style="{'width': `${getSuccess}%`}">
        <div  class="percentaje--container">
          <p v-if="!hideProgress">
          {{successCount}}<span> ({{Math.round(getSuccess)}}%)</span>
        </p>
        </div>
        
      </div>
      <div  @mouseover="showErrorPercentage = true" @mouseleave="showErrorPercentage = false" class="WatUxQuantiProgressBar--Error" :style="{'width': `${getError}%`}">
        <div class="percentaje--container">

          <p v-if="!hideProgress">
            {{errorCount}}<span> ({{Math.round(getError)}}%)</span>
          </p>
        </div>
      </div>
      <div @mouseover="showTimeoutPercentage = true" @mouseleave="showTimeoutPercentage = false" class="WatUxQuantiProgressBar--Timeout" :style="{'width': `${getTimeout}%`}">
        <div class="percentaje--container">
          <p v-if="!hideProgress">
            {{timeoutCount}}<span> ({{Math.round(getTimeout)}}%)</span>
          </p>
        </div>
      </div>
  </div>
</template> -->
<template >
  <div class="WatUxQuantiProgressBar">
      <p class="WatUxQuantiProgressBar--Name" v-if="name.length > 0">{{ name }}</p>
      <div  class="WatUxQuantiProgressBar--Success" :class="{'withName':name.length > 0}" :style="{'width': `${getSuccess}%`}">
        <div  class="percentaje--container">
          <p>
          <span v-show="Math.round(getSuccess) > 0"> ({{Math.round(getSuccess)}}%)</span>
        </p>
        </div>
        
      </div>
      <div  class="WatUxQuantiProgressBar--Error" :class="{'withName':name.length > 0}" :style="{'width': `${getError}%`}">
        <div class="percentaje--container">
          <p>
            <span v-show="Math.round(getError) > 0"> ({{Math.round(getError)}}%)</span>
          </p>
        </div>
      </div>
      <div class="WatUxQuantiProgressBar--Timeout" :class="{'withName':name.length > 0}" :style="{'width': `${getTimeout}%`}">
        <div class="percentaje--container">
          <p>
            <span v-show="Math.round(getTimeout) > 0"> ({{Math.round(getTimeout)}}%)</span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'WatUxQuantiProgressBar',
  props:{
    successCount: Number,
    errorCount: Number,
    timeoutCount: Number,
    name:{
      type:String,
      default:''
    }
  },
  computed:{
    getSuccess(){
      return (this.successCount * 100) / (this.successCount + this.errorCount + this.timeoutCount)
    },
    getError(){
      return (this.errorCount * 100) / (this.successCount + this.errorCount + this.timeoutCount)
    },
    getTimeout(){
      return (this.timeoutCount * 100) / (this.successCount + this.errorCount + this.timeoutCount)
    }
  }
}
</script>

<style lang="scss" scoped>
.WatUxQuantiProgressBar{
  background: transparent;
  min-height: 9px;
  max-height: 25px;
  width: 100%;
  display: flex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  color: #FFFFFF;
  align-items: center;
  overflow: hidden;
  &--Name{
    font-size: 11px;
    color: black;
    padding: 0 10px 0 0;
  }
  p{
    padding: 12px;
  }
  span{
    font-weight: 500;
  }
  .percentaje--container{
    // opacity: 0;
    top: -20px;
    transition: all 0.4s ease;
    font-size: 60%;

  }
  div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 9px;
    &.withName{
      height: 9px;
    }
  }
  &--Success{
    background: #96C800;
  }
  &--Error{
    background: #FF1564;
  }
  &--Timeout{
    background: #FFC06F;
  }

}
</style>
