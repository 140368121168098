<template lang="html">
  <div class="Monitor">
    <MonitorSidebar 
      class="Monitor--Sidebar"
      v-if="reportDataGenerated" 
      ref="MonitorSidebar"
      :hideDashboardSocioDemographics="test.test.hideDashboardSocioDemographics"
      :questionsByTask="questionsByTask" 
      :globalFilters="globalFilters" 
      :globalQuestionFilters="globalQuestionFilters"
      :globalDemographicFilters="globalDemographicFilters"
      :filterVariables="globalVariableFilters"
      v-on:remove-variable="$emit('remove-variable', $event)"
      v-on:updated-filter="fetchQuestionsWithFilters()"
      v-on:save-filter="beforeSaveFilter()"
      v-on:question-selected="scrollToQuestion($event)"/>
    <div class="Monitor--Content">
      <div class="Monitor--Header">
        <h1>{{$t('watmonitor_inform')}} "{{ this.report.reportName }}"</h1>
        <div class="Monitor--ProgressBar">
          <div v-if="showProgressBar">
            <h1 v-if="!showTemporalMessage">{{$t('watmonitor_progress_bar_title')}}</h1>
            <div class="progress" v-if="!showTemporalMessage">
              <div class="progress-bar" role="progressbar" :style="{ width: progressPercent + '%' }" aria-valuenow="progressPercent" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <h1 v-if="showTemporalMessage" class="temporal-message"><i class="fa fa-bell" aria-hidden="true"></i> {{$t('suite_available_excel_aggregated')}}</h1>
        </div>
        <div class="Monitor--ColorIcon" v-on:click="$emit('open-color-editor')">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 19.5C7.61305 19.5 5.32387 18.5518 3.63604 16.864C1.94821 15.1761 1 12.8869 1 10.5C1 8.11305 1.94821 5.82387 3.63604 4.13604C5.32387 2.44821 7.61305 1.5 10 1.5C14.97 1.5 19 5.082 19 9.5C19 10.56 18.526 11.578 17.682 12.328C16.838 13.078 15.693 13.5 14.5 13.5H12C11.5539 13.4928 11.1181 13.635 10.7621 13.904C10.4061 14.173 10.1503 14.5533 10.0353 14.9844C9.92034 15.4155 9.95284 15.8727 10.1276 16.2833C10.3025 16.6938 10.6095 17.0341 11 17.25C11.1997 17.4342 11.3366 17.6764 11.3915 17.9425C11.4465 18.2085 11.4167 18.4851 11.3064 18.7334C11.196 18.9816 11.0107 19.1891 10.7764 19.3266C10.5421 19.4641 10.2705 19.5247 10 19.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.5 9C5.5 9.26522 5.60536 9.51957 5.79289 9.70711C5.98043 9.89464 6.23478 10 6.5 10C6.76522 10 7.01957 9.89464 7.20711 9.70711C7.39464 9.51957 7.5 9.26522 7.5 9C7.5 8.73478 7.39464 8.48043 7.20711 8.29289C7.01957 8.10536 6.76522 8 6.5 8C6.23478 8 5.98043 8.10536 5.79289 8.29289C5.60536 8.48043 5.5 8.73478 5.5 9ZM9.5 6C9.5 6.26522 9.60536 6.51957 9.79289 6.70711C9.98043 6.89464 10.2348 7 10.5 7C10.7652 7 11.0196 6.89464 11.2071 6.70711C11.3946 6.51957 11.5 6.26522 11.5 6C11.5 5.73478 11.3946 5.48043 11.2071 5.29289C11.0196 5.10536 10.7652 5 10.5 5C10.2348 5 9.98043 5.10536 9.79289 5.29289C9.60536 5.48043 9.5 5.73478 9.5 6ZM13.5 9C13.5 9.26522 13.6054 9.51957 13.7929 9.70711C13.9804 9.89464 14.2348 10 14.5 10C14.7652 10 15.0196 9.89464 15.2071 9.70711C15.3946 9.51957 15.5 9.26522 15.5 9C15.5 8.73478 15.3946 8.48043 15.2071 8.29289C15.0196 8.10536 14.7652 8 14.5 8C14.2348 8 13.9804 8.10536 13.7929 8.29289C13.6054 8.48043 13.5 8.73478 13.5 9Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="Monitor--TaskList">
        <WatUxQuantiMonitorSummary 
          class="MonitorContent--Summary"
          ref="WatUxQuantiMonitorSummary"
          v-if="showClickTestSummary" 
          :tasks="questionsByTask"
          :reportIndex="reportTab"
          :type="test.test.testCommercialType"
          :isV3Chart="true"/>
        <div class="Monitor--Task" v-for="(t, index) in questionsByTask">
          <h1>
            <span>{{ t.taskNum }}. </span>
            {{ t.name }}
          </h1>
          <div v-for="q in t.questions">
            <ChartNew 
              v-if="q.questionType != 'CLICK_TEST' && q.questionType != 'WATUX_QUANTI'"
              :id="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
              :ref="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
              :testId="testId"
              :i18n="i18n"
              :question="q"
              :personalization="report.personalizationData"
              v-on:change-page="handlePageChange($event, q, getQuestionIndex(q.questionId, q.taskNum))"
              v-on:open-media="openMedia($event)"/>
            <WatClickTestMonitor
                v-if="q.questionType == 'CLICK_TEST' && q.firstLoadCompleted"
                :id="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
                :ref="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
                :question="q"
                :reportIndex="reportTab"
                :taskNum="q.taskNum"
                :clickTestQuestions="q.clickTestData"
                :isV3Chart="true"/>
            <WatUxQuantiMonitorV3
              v-if="q.questionType == 'WATUX_QUANTI'"
              :id="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
              :ref="'Chart-'+reportTab+'-'+getQuestionIndex(q.questionId, q.taskNum)" 
              :reportIndex="reportTab"
              :question="q"
              :test="test" 
              :taskNum="q.taskNum"
              :i18n="i18n"
              :isV3Chart="true"/>
          </div>
        </div>
      </div>
    </div>
    <ModalInput :show="showFilterNameInput"
      :title="$t('watmonitor_save_filters')"
      :cancelButtonText="$t('watmonitor_action_cancel')"
      :confirmButtonText="$t('watmonitor_action_save')"
      :disableConfirm="filterName.length <= 2"
      v-on:cancel="showFilterNameInput=false"  
      v-on:confirm="saveFilter()">
      <Input 
        :label="$t('watmonitor_variable_name_label')"
        :placeholder="$t('watmonitor_example_placeholder')"
        v-model="filterName"
        />
    </ModalInput>
    <ModalFullScreen v-if="showMedia" v-on:close="closeMedia()">
      <div class="Monitor--Viewer">
        <MediaViewer v-if="mediaData.images" class="ChartMonitor--MediaViewer" :images="$filters.formatChartMedia(mediaData.images)"/>
        <MediaViewer v-if="mediaData.video" class="ChartMonitor--MediaViewer"  :youTubeVideo="$filters.formatChartMedia([mediaData.video])"/>
      </div>
    </ModalFullScreen>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ModalInput from '~/components/ModalInput/ModalInput.vue'
import Input from '~/components/Input/Input.vue'
import MonitorSidebar from './MonitorSidebar.vue'
import ChartNew from './ChartNew.vue'
import Swal from 'sweetalert2'
import { cloneDeep } from 'lodash';
import { generateXDimensions, generateXfilter, generateFilters, generateYdimensions } from '~/utils/watmonitor/'
import ModalFullScreen from '../ModalFullScreen/ModalFullScreen.vue'
import MediaViewer from '~/components/MediaViewer'
import WatClickTestMonitor from '~/components/WatMonitor/WatClickTestMonitor'
import WatUxQuantiMonitorSummary from '~/components/WatMonitor/WatUxQuantiMonitorSummary'
import WatUxQuantiMonitorV3 from '~/components/WatMonitor/WatUxQuantiMonitorV3'

export default {
  name: 'MonitorNew',
  props: {
    i18n:Object,
    test:Object,
    testId:String,
    report:Object,
    provinces: Array,
    regions: Array,
    nielsen: Array,
    questionsToFilter: Array,
    defaultFilterValues:Array,
    filterVariables: Array,
    progressPercent: {
      type: Number,
      default: 0
    },
    showProgressBar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MonitorSidebar,
    ChartNew,
    ModalInput,
    Input,
    ModalFullScreen,
    MediaViewer,
    WatClickTestMonitor,
    WatUxQuantiMonitorSummary,
    WatUxQuantiMonitorV3
  },
  watch:{
    filterVariables(newValue, oldValue){
      this.createVariableFilters()
    },
    progressPercent(newValue, oldValue) {
      this.showTemporalMessage = false;
      if (newValue == 100) {
        this.showTemporalMessage = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      'reportsList',
      'reportTab',
      'isAuthenticated'
    ]),
    questionsByTask(){
      const questions = _.groupBy(this.report.questions,'taskNum');
      let tasks = Object.entries(questions).map(([index, question]) => {
        let task = this.getTaskByNum(index);
        task.questions = questions[index]
        return task
      });
      return tasks.sort((a, b) => (Number.parseInt(a.taskNum) >= Number.parseInt(b.taskNum)) ? 1 : -1)
    },
    isAnyTaskWatUxQuantiOrClickTest(){
      if(this.test.testTasks == undefined) return false
      return this.test.testTasks.filter(t => t.taskType == 'UX_QUANTI').length > 0 || this.test.testTasks.filter(t => t.taskType == 'CLICK_TEST').length > 0
    },
    showClickTestSummary(){
      let validation = this.showTaskSummary && this.isAnyTaskWatUxQuantiOrClickTest
      for(let q of this.report.questions){
        if((q.questionType == 'CLICK_TEST' || q.questionType == 'WATUX_QUANTI') && !q.firstLoadCompleted){
          validation = false
        }
      }
      return validation
    }
  },
  data() {
    return {    
      globalFilters:[],
      globalDemographicFilters:[],
      globalQuestionFilters:[],
      globalVariableFilters:[],
      reportDataGenerated: false,
      showFilterNameInput: false,
      filterName:'',
      mediaData:{images:[], video:''},
      showMedia:false,
      showTaskSummary: true,
      showTemporalMessage: false
    }
  },
  methods:{
    isElementInViewport(el){
      const rect = el.getBoundingClientRect();
      return (rect.bottom >= 0 && rect.top < (window.innerHeight || document.documentElement.clientHeight))
    },
    generateSummary(){
      const WatUxQuantiMonitorSummary = this.$refs.WatUxQuantiMonitorSummary
      if(WatUxQuantiMonitorSummary != undefined){
        WatUxQuantiMonitorSummary.generateSummaryCharts(0)
      }
    },
    generateChart(chartIndex){
      const chart = this.$refs['Chart-' + this.reportTab + '-' + chartIndex]
      if(chart != undefined) this.$refs['Chart-' + this.reportTab + '-' + chartIndex].generate()
    },
    getTaskByNum(taskNum){
      if(taskNum == -1) return {taskNum: taskNum, name:this.$t('watmonitor_sociodemographic_data')}
      const tasks = _.cloneDeep(this.test.testTasks)
      return tasks.filter(t => t.taskNum == taskNum)[0]
    },
    getQuestionIndex(questionId, taskNum){
      const index = this.report.questions.map(function(q,i) {
        if(q.questionId == questionId && q.taskNum == taskNum) return i
      }).join('')
      return index
    },
    generateAllReportFilters(){
      this.createGlobalFilters()
      this.createGlobalDemographicFilters()
      this.createGlobalQuestionFilters()
      this.createVariableFilters()
    },
    createGlobalFilters(){
      const provinces = _.cloneDeep(this.provinces)
      this.globalFilters = [
        {taskNum:-1,questionNum:3,values:[{id:0,value:"Hombre", selected:false},{id:1,value:"Mujer",selected:false}], split:false},
        {taskNum:-1,questionNum:2,ranges:[{from:16,to:100}], split:false},
        {taskNum:-1,questionNum:1,values:provinces, split:false}
      ]
    },
    createGlobalDemographicFilters(){
      const regions = _.cloneDeep(this.regions)
      const nielsen = _.cloneDeep(this.nielsen)
      let groupsNielsen = []
      for(let r of nielsen){
        const nielsenByParentId = this.$parent.getProvincesByNielsenId(r.id)
        groupsNielsen.push({type:'NIELSEN',groupId:r.id,groupName:r.value,selected:false,values:nielsenByParentId.map(v => v.id)})
      }

      let groupsRegion = []
      for(let r of regions){
        const regionsByParentId = this.$parent.getProvincesByParentId(r.id)
        groupsRegion.push({type:'REGION',groupId:r.id,groupName:r.value,selected:false,values:regionsByParentId.map(v => v.id)})
      }
      this.globalDemographicFilters.push({
        questionText:'Comunidades autónomas',
        taskNum: -1,
        questionNum: 1,
        questionFakeName:'REGION',
        values: [],
        groups: groupsRegion,
        split:false
      })
      this.globalDemographicFilters.push({
        questionText:'Zonas geográficas',
        taskNum: -1,
        questionNum: 1,
        questionFakeName:'NIELSEN',
        values: [],
        groups: groupsNielsen,
        split:false
      })
    },
    createGlobalQuestionFilters(){
      this.globalQuestionFilters =  _.cloneDeep(this.questionsToFilter);
    },
    createVariableFilters(){
      this.globalVariableFilters =  _.cloneDeep(this.filterVariables);
    },
    getAgeQuestionRanges(){
      const question = this.report.questions.filter(q => {
        if((q.questionId == 2 || q.questionNum == 2)  && q.taskNum == -1) return q
      })[0]
      if(question == undefined) return [{from:16, to:100}]
      return question.ranges
    },
    fetchQuestionsWithFilters(){
      const questions = this.report.questions
      for(let q of questions){
        this.createFiltersForQuestion(q)
        if(q.questionType == 'WATUX_QUANTI') {
          for(let uqd of q.uxQuantiData){
            this.createFiltersForQuestion(uqd)
          }
        }
        if(q.questionType == 'CLICK_TEST') {
          for(let ctd of q.clickTestData){
            this.createFiltersForQuestion(ctd)
          }
        }
         q.isInViewport =  this.isElementInViewport(document.getElementById('Chart-'+this.reportTab+'-'+this.getQuestionIndex(q.questionId, q.taskNum)))
      }
      this.$emit('fetchReportWithFilters')
    },
    createFiltersForQuestion(q){
      const defaultRangesForSplit = this.getAgeQuestionRanges()
      const xDimension = generateXDimensions(q, 0)
      q.xDimension = xDimension
      q.xFilter = []
      generateFilters(this.globalFilters, q)
      generateFilters(this.globalQuestionFilters, q)
      generateFilters(this.globalDemographicFilters, q)
      generateYdimensions(this.globalFilters.concat(this.globalQuestionFilters.concat(this.globalDemographicFilters)), q, defaultRangesForSplit)
    },
    generateExcelFilters(){
      let question = {
        xFilter : []
      }
      const defaultRangesForSplit = this.getAgeQuestionRanges()
      generateFilters(this.globalFilters, question)
      generateFilters(this.globalQuestionFilters, question)
      generateFilters(this.globalDemographicFilters, question)
      generateYdimensions(this.globalFilters.concat(this.globalQuestionFilters.concat(this.globalDemographicFilters)), question, defaultRangesForSplit)
      return question
    },
    beforeSaveFilter(){
      this.showFilterNameInput = true
    },
    saveFilter(){
      this.showFilterNameInput = false
      let question = {
        xFilter : []
      }
      generateFilters(this.globalFilters, question)
      generateFilters(this.globalQuestionFilters, question)
      generateFilters(this.globalDemographicFilters, question)
      const variable = {
        name: this.filterName,
        xFilter: question.xFilter
      }
      this.filterName = ''
      this.$emit('save-filter', variable)
    },
    scrollToQuestion(event){
      const chart = 'Chart-'+this.reportTab+'-'+this.getQuestionIndex(event.question.questionId, event.question.taskNum)
      document.getElementById(chart).scrollIntoView({behavior: 'smooth', block: 'start'})
    },
    handlePageChange(event, question, index){
      question.page = event.page
      this.$emit('fetch-question', { question: question, chartIndex: index})
    },
    openMedia(event){
      this.showMedia = true
      this.mediaData = event
    },
    closeMedia(){
      this.showMedia = false
    }
  },
  async mounted(){
    this.generateAllReportFilters()
    this.reportDataGenerated = true
  }
}
</script>
<style lang="scss" scoped>
.Monitor{
  height: 100%;
  display: flex;
  &--Viewer{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &--Content{
    width: calc(100% - 350px);
  }
  &--Header{
    padding: 20px 12px;
    border-bottom: 1px solid #eaeaea;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1{
      font-family: Bariol Bold;
      font-size: 18px;
      font-weight: 700;
    }
  }
  &--ColorIcon{
    width: 50px;
    height: 44px;
    padding: 10px 13px;
    border-radius: 4px;
    background: #F8F8F8;
    margin-bottom: -7px;
    margin-top: -8px;
    cursor: pointer;
  }
  &--ProgressBar {
    h1 {
      font-size: 16px;
      text-align: left;
      margin-bottom: 5px;
    }
    .temporal-message {
      background-image: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: #87111100;
      animation: fadeOut 6s forwards;
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        display: none;
      }
    }

    .progress {
      margin-right: 400px;
      height: 20px;
      width: 100%;
      border-radius: 10px;
      background-color: rgb(255, 255, 255);
      overflow: hidden;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
      padding: 5px;
    }
    .progress-bar {
      height: 100%;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      border-radius: 10px;
      transition: width 0.3s ease-in-out;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }
  &--TaskList{
    padding:30px;
    height: calc(100vh - 125px);
    overflow-y: scroll;
    gap: 25px;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar-track{
        background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
        background: #8134ff;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 20px;
        background: #8134ff;
    }
  }
  &--Task{
    display: flex;
    flex-direction: column;
    gap: 16px;
    h1{
      font-family: Bariol Bold;
      font-size: 24px;
      font-weight: 700;
      text-align: justify;
      

      span{
        background-image: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
}
</style>